import { useEffect, useState } from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getInventoryByStore } from "../../../redux/actions/inventoryAction.js";

import PaginationComponent from "../../components/pagination/PaginationComponent";
import AdjustStockModal from "./AdjustStockModal.jsx";

const StoreStockPage = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { inventoryItems, error, message } = useSelector((state) => state.inventory);

    const storeId = user?.store._id;

    const [showModal, setShowModal] = useState(false);

    const [selectedInventory, setSelectedInventory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const handleShow = (product) => {
        setSelectedInventory(product);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    useEffect(() => {
        if (storeId) {
            dispatch(getInventoryByStore(storeId));
        }
    }, [dispatch, storeId]);

    // const extractedProducts = useMemo(() => {
    //     if (inventoryItems && inventoryItems.length > 0) {
    //         return inventoryItems.map((stock) => ({
    //             ...stock.product,
    //             quantity: stock.quantity,
    //         }));
    //     }
    //     return [];
    // }, [inventoryItems]);

    const productsPerPage = 14;

    // Calculate total number of pages
    const totalPages = Math.ceil(inventoryItems.length / productsPerPage);

    // Calculate the products to display based on current page
    const startIndex = (currentPage - 1) * productsPerPage;
    const currentStocks = inventoryItems.slice(startIndex, startIndex + productsPerPage);

    useEffect(() => {
        if (error) {
            dispatch({ type: "clearErrors" });
        }
        if (message) {
            dispatch({ type: "clearSuccess" });
        }
    }, [dispatch, error, message]);


    return (
        <div className="container-fluid">
            <h2 className="text-center">Inventory In Store</h2>
            {error ? (
                <Alert variant="danger" className="mt-4">
                    {error}
                </Alert>
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="table-container">
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentStocks.length > 0 ? (
                                        currentStocks.map((inventory, index) => (
                                            <tr key={index}>
                                                <td>{startIndex + index + 1}</td>
                                                <td>{inventory?.product?.name}</td>
                                                <td>{inventory?.product?.category?.name}</td>
                                                <td>{inventory?.quantity}</td>
                                                <td>Ksh.{inventory?.product?.sellingPrice}</td>
                                                <td>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => handleShow(inventory)}
                                                        aria-label={`Adjust stock for ${inventory?.product?.name}`}
                                                    >
                                                        Adjust Stock
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center text-danger">
                                                No products available in this store
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        {/* Only show pagination if the total number of products exceeds productsPerPage */}
                        {currentStocks.length > productsPerPage && (
                            <div className="d-flex justify-content-center mt-2">
                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={setCurrentPage}
                                />
                            </div>
                        )}
                        <AdjustStockModal show={showModal} handleClose={handleClose} inventory={selectedInventory} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default StoreStockPage;
