/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { editCategory, getAllCategories, } from "../../../redux/actions/categoryAction.js";
import { variantTypes } from "../../data/staticData.js";

const EditCategoryModal = ({ show, onHide, category }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.category);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [availableIn, setAvailableIn] = useState("");


  useEffect(() => {
    if (category) {
      setName(category.name);
      setDescription(category.description);
      setAvailableIn(category.availableIn); // Initialize availableIn from category
    }
  }, [category]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      description,
      availableIn, // Include availableIn in the data sent to the action
    };

    dispatch(editCategory(category._id, categoryData)); // Pass the category ID for editing
  };

  useEffect(() => {
    if (success) {
      toast.success("Category updated successfully!");
      dispatch(getAllCategories()); // Fetch updated category list
      setName("");
      setDescription("");
      setAvailableIn(""); // Reset availableIn
      onHide(); // Close the modal
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            // required // Optional based on your requirements
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Available In</Form.Label>
            <Form.Select
              value={availableIn}
              onChange={(e) => setAvailableIn(e.target.value)} // Handle change for availableIn
              required
            >
              <option value="">Select Available Option</option>
              {variantTypes.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Updating..." : "Update Category"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCategoryModal;
