/* eslint-disable react/prop-types */
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { server } from "../../../server/server";

const ReconcileModal = ({ show, handleClose, inventoryItem, fetchInventoryItems }) => {

    const [formData, setFormData] = useState({
        productName: "",
        openingInventory: 0,
        closingInventory: 0,
    });

    const productName = inventoryItem && `${inventoryItem.product.name} ${inventoryItem?.size} ${inventoryItem?.color}`;

    useEffect(() => {
        // Set form data if editing an existing item
        if (inventoryItem) {
            setFormData({
                productName: inventoryItem?.product?.name,
                openingInventory: inventoryItem?.closingInventory,
                closingInventory: inventoryItem?.closingInventory,
            });
        } else {
            setFormData({ openingInventory: 0, closingInventory: 0 });
        }
    }, [inventoryItem]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value === "" ? "" : Number(value), // Handle empty string case
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = {
            // product: formData.product,
            openingInventory: formData.openingInventory,
            closingInventory: formData.closingInventory,
        };

        console.log(newFormData)

        try {
            await axios.put(`${server}/inventory/reconcile/${inventoryItem._id}`, newFormData, { withCredentials: true });
            toast.success("Inventory item reconciled successfully");
            fetchInventoryItems();
            handleClose();
        } catch (error) {
            toast.error(error.response.data.message || "Error updating inventory");
        }

    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Reconcile Inventory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formProductName">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="productName"
                            value={productName}
                            onChange={handleChange}
                            min="0"
                            disabled
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formOpeningInventory">
                        <Form.Label>Opening Inventory</Form.Label>
                        <Form.Control
                            type="number"
                            name="openingInventory"
                            value={formData.openingInventory}
                            onChange={handleChange}
                            min="0"
                            disabled
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formClosingInventory" className="mt-3">
                        <Form.Label>Closing Inventory</Form.Label>
                        <Form.Control
                            type="number"
                            name="closingInventory"
                            value={formData.closingInventory}
                            onChange={handleChange}
                            min="0"
                            required
                        />
                    </Form.Group>

                    <Button variant="primary" className="mt-2 float-end" type="submit">
                        Reconcile
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ReconcileModal;
