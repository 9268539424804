import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { handleInventoryFilter } from "../../components/utils/handleFilter";
import ActionButtons from "../../components/action-buttons/ActionButtons.jsx";

import { deleteInventoryItem, getAllInventoryItems } from "../../../redux/actions/inventoryAction"; // Adjust the import path as necessary


import CreateInventory from "./CreateInventory.jsx";
import EditInventory from "./EditInventory.jsx";
import ReconcileModal from "./ReconcileModal.jsx";

const InventoryPage = () => {
    const { inventoryItems, isLoading, error } = useSelector((state) => state.inventory);
    const dispatch = useDispatch();

    // console.log(inventoryItems)

    const [records, setRecords] = useState(inventoryItems || []);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleShowCreateModal = () => setShowCreateModal(true);
    const handleCloseCreateModal = () => setShowCreateModal(false);

    // Inside InventoryPage
    const [showReconcileModal, setShowReconcileModal] = useState(false);
    // const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        dispatch(getAllInventoryItems());
    }, [dispatch]);


    useEffect(() => {
        setRecords(inventoryItems || []);
    }, [inventoryItems]);

    const handleReconcile = (item) => {
        setSelectedItem(item);
        setShowReconcileModal(true);
    };

    const handleCloseReconcileModal = () => {
        setShowReconcileModal(false);
        setSelectedItem(null);
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        const filteredData = handleInventoryFilter(inventoryItems, term); // Use the utility function
        setRecords(filteredData);
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedItem(null);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            dispatch(deleteInventoryItem(id))
                .then(() => {
                    toast.success("Inventory item deleted successfully");
                    dispatch(getAllInventoryItems()); // Refresh the inventory list after deletion
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message || "Error deleting item");
                });
        }
    };

    useEffect(() => {
        // Show error toast if there's an error in fetching data
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const columns = [
        {
            name: "#",
            selector: (_row, index) => index + 1,
            sortable: false,
            width: "60px",
        },
        {
            name: 'Product',
            selector: (row) => row?.product?.name,
            sortable: true,
            width: "120px",
        },
        {
            name: 'Opening',
            selector: (row) => row?.openingInventory,
            sortable: true,
            width: "100px",

        },
        {
            name: 'Closing',
            selector: (row) => row?.closingInventory,
            sortable: true,
            width: "100px",
        },
        {
            name: "Cost Price",
            selector: (row) => `Ksh.${row?.product?.costPrice?.toFixed(2)}`,
            width: "120px",
        },
        {
            name: "Selling Price",
            selector: (row) => `Ksh.${row?.product?.sellingPrice?.toFixed(2)}`,
            width: "130px",
        },
        {
            name: 'Color',
            selector: row => row?.color,
            width: "80px",
        },
        {
            name: 'Size',
            selector: row => row?.size,
            width: "100px",
        },
        {
            name: 'Available',
            selector: row => row?.quantity,
            width: "100px",
        },
        {
            name: 'CreatedAt',
            selector: row => new Date(row?.createdAt).toLocaleString('en-KE'),
        },
        {
            name: 'Last Updated',
            selector: row => new Date(row?.updatedAt).toLocaleString('en-KE'),
        },
        {
            name: "Actions",
            cell: (row) => (
                <ActionButtons
                    onEdit={() => handleEdit(row)}
                    onDelete={() => handleDelete(row._id)}
                    onReconcile={() => handleReconcile(row)}
                />
            ),
            width: "300px",
            ignoreRowClick: true,
        },
    ];

    return (
        <div className="container-fluid">
            <Row className="my-3 justify-content-between align-items-center">
                <Col xs={12} md={4}>
                    <h2>Inventory</h2>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Control
                        type="text"
                        placeholder="Search records..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </Col>
                <Col xs={6} md={4}>
                    <Button className="float-end mb-2" variant="primary" onClick={handleShowCreateModal}>
                        Add Inventory Item
                    </Button>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={records}
                pagination
                striped
                highlightOnHover
                progressPending={isLoading} // Show loader while fetching data
            />

            {/* Create Inventory Modal */}
            <CreateInventory
                show={showCreateModal}
                handleClose={handleCloseCreateModal}
                fetchInventoryItems={() => dispatch(getAllInventoryItems())}
            />

            {/* Edit Inventory Modal */}
            <EditInventory
                show={showEditModal}
                handleClose={handleCloseEditModal}
                inventoryItem={selectedItem}
                fetchInventoryItems={() => dispatch(getAllInventoryItems())}
            />

            {/* // ReconcileModal usage */}
            <ReconcileModal
                show={showReconcileModal}
                handleClose={handleCloseReconcileModal}
                inventoryItem={selectedItem}
                fetchInventoryItems={() => dispatch(getAllInventoryItems())}
            />
        </div>
    );
};

export default InventoryPage;