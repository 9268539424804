/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getAllCategories } from "../../../redux/actions/categoryAction.js";
import { createSubcategory, getAllSubcategories, } from "../../../redux/actions/subcategoryAction.js";

const CreateSubcategoryModal = ({ show, onHide }) => {
  const dispatch = useDispatch();

  // Fetch categories to display in the dropdown
  const { categories } = useSelector((state) => state.category);

  // Subcategory creation state
  const { isLoading, success, error } = useSelector((state) => state.subcategory);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("Description");
  const [categoryId, setCategoryId] = useState("");

  const [availableTypes, setAvailableTypes] = useState([]);
  const [newType, setNewType] = useState("");

  const handleAddType = () => {
    const trimmedType = newType.trim();
    if (trimmedType && !availableTypes.includes(trimmedType)) {
      setAvailableTypes((prev) => [...prev, trimmedType]);
      setNewType("");
    } else if (availableTypes.includes(trimmedType)) {
      toast.error("This type already exists!");
    }
  };


  const handleRemoveType = (index) => {
    setAvailableTypes((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if availableTypes is empty
    // if (availableTypes.length === 0) {
    //   toast.error("Please add at least one type before submitting!");
    //   return; 
    // }

    const subcategoryData = {
      name,
      description,
      category: categoryId,
      types: availableTypes,
    };

    // console.log(subcategoryData)

    dispatch(createSubcategory(subcategoryData));
  };

  useEffect(() => {
    if (success) {
      toast.success("Subcategory created successfully!");
      dispatch(getAllSubcategories());
      setName("");
      setDescription("");
      setCategoryId("");
      setAvailableTypes([]);
      setNewType("");
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // Fetch categories for the dropdown when the component mounts
  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Sub Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Main Category</Form.Label>
            <Form.Select
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="">Select main category</option>
              {categories &&
                categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Sub Category Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter category name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Available Types</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                value={newType}
                onChange={(e) => setNewType(e.target.value)}
                placeholder="Enter a new type"
              />
              <Button variant="secondary" onClick={handleAddType}>
                Add Type
              </Button>
            </InputGroup>
            <div className="mt-3">
              {availableTypes.map((typeItem, index) => (
                <span
                  key={index}
                  className="badge bg-primary d-inline-flex align-items-center me-2"
                >
                  {typeItem}
                  <AiOutlineClose
                    size={15}
                    className="ms-2 cursor-pointer"
                    onClick={() => handleRemoveType(index)}
                    aria-label={`Remove ${typeItem}`}
                  />
                </span>
              ))}
            </div>
          </Form.Group>


          <Button className="float-end" variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Subcategory"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateSubcategoryModal;
