/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createProduct, getAllProducts, } from "../../../../redux/actions/productAction.js";

import ColorSelector from "../utils/ColorSelector.jsx";
import SizeSelector from "../utils/size/SizeSelector.jsx";

import { getAllCategories } from "../../../../redux/actions/categoryAction.js";
import { getAllSubcategories } from "../../../../redux/actions/subcategoryAction.js";

const CreateProductModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { isLoading, success, error } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);


  const [name, setName] = useState("Clere");
  const [costPrice, setCostPrice] = useState("60");
  const [sellingPrice, setSellingPrice] = useState("80");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  const [sizeDetails, setSizeDetails] = useState([]);
  const [size, setSize] = useState("");

  const [colors, setColors] = useState([]);
  const [color, setColor] = useState("");



  useEffect(() => {
    if (show) {
      dispatch(getAllCategories());
      dispatch(getAllSubcategories());
    }
  }, [show, dispatch]);

  const filterSubcategoriesByCategory = (categoryId) => {
    return subcategories && subcategories.filter(
      (subcategory) => subcategory?.category?._id === categoryId
    );
  };

  const handleAddColor = () => {
    if (color && !colors.includes(color)) {
      setColors((prevColors) => [...prevColors, color]);
      setColor(""); // Reset color select after adding
    }
  };

  const handleRemoveColor = (colorToRemove) => {
    setColors((prevColors) => prevColors.filter((c) => c !== colorToRemove));
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    // if (sizeDetails.length === 0) {
    //   toast.error("Please add at least one size.");
    //   return;
    // }

    // Combine sizes and selectedCategory if needed
    const updatedSizeDetails = sizeDetails.map((item) => ({
      ...item,
    }));

    const productData = {
      name,
      category,
      subcategory,
      costPrice,
      sellingPrice,
      colors,
      sizeDetails: updatedSizeDetails,
    };
    // console.log(productData)
    dispatch(createProduct(productData));
  };

  // Function to handle adding sizes
  const handleAddSize = (selectedCategory, size) => {
    if (size === "") {
      toast.error("Size cannot be empty");
      return;
    }
    setSizeDetails((prevDetails) => {
      const existingCategory = prevDetails.find(
        (item) => item.sizeCategory === selectedCategory
      );

      if (existingCategory) {
        return prevDetails.map((item) => item.sizeCategory === selectedCategory ? { ...item, sizes: [...item.sizes, size] } : item);
      } else {
        return [
          ...prevDetails,
          { sizeCategory: selectedCategory, sizes: [size] },
        ];
      }
    });
    // setSelectedCategory(selectedCategory);
  };

  // Function to handle removing a size
  const handleRemoveSize = (category, sizeToRemove) => {
    setSizeDetails(
      (prevDetails) =>
        prevDetails
          .map((item) =>
            item.sizeCategory === category
              ? { ...item, sizes: item.sizes.filter((s) => s !== sizeToRemove) }
              : item
          )
          .filter((item) => item.sizes.length > 0) // Remove empty categories
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(getAllProducts());
      setSizeDetails([]); // Reset size details
      onHide();
    }
  }, [success, dispatch, onHide]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setSubcategory(""); // Reset subcategory when category changes
                  }}
                  required
                >
                  <option value="">Select a category</option>
                  {categories &&
                    categories.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Sub Category</Form.Label>
                <Form.Control
                  as="select"
                  value={subcategory}
                  onChange={(e) => setSubcategory(e.target.value)}
                  required
                >
                  <option value="">Select a sub category</option>
                  {category &&
                    filterSubcategoriesByCategory(category).map((subcat) => (
                      <option key={subcat._id} value={subcat._id}>
                        {subcat.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Cost Price</Form.Label>
                <Form.Control
                  type="number"
                  value={costPrice}
                  onChange={(e) => setCostPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Selling Price</Form.Label>
                <Form.Control
                  type="number"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                  min={0}
                  required
                />
              </Form.Group>
            </Col>
            {/* Use ColorSelector component */}
            <ColorSelector
              colors={colors}
              color={color}
              onChange={(e) => setColor(e.target.value)} // Update color state
              onAddColor={handleAddColor}
              onRemoveColor={handleRemoveColor}
              subcategoryId={subcategory}
            />

            {/* Use SizeSelector component */}
            <SizeSelector
              sizeDetails={sizeDetails}
              onAddSize={handleAddSize}
              onRemoveSize={handleRemoveSize}
              selectedCategory={subcategory} // Pass selectedCategory
              // setSelectedCategory={setSelectedCategory} // Pass setter function
              size={size} // Pass size
              setSize={setSize} // Pass size setter function
              subcategoryId={subcategory}
            />
          </Row>
          <div className="text-end mt-4">
            <Button
              className="ms-3"
              type="submit"
              variant="primary"
              disabled={isLoading}
            >
              {isLoading ? "Creating..." : "Create Product"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProductModal;
