/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductVariants } from "../../../../redux/actions/productVariantAction";

const ColorSelector = ({ colors, onChange, onAddColor, onRemoveColor, subcategoryId }) => {
  const { productVariants } = useSelector((state) => state.productVariant);
  const [color, setColor] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductVariants());
  }, [dispatch]);

  // Filter the product variants to only include the desired subcategory
  const colorVariants = productVariants && productVariants?.find(
    (variant) => variant?.subcategory?._id === subcategoryId && variant.variantType === "Color"
  );

  const availableColors = colorVariants?.variations || [];

  const handleColorChange = (event) => {
    setColor(event.target.value);
    onChange(event);
  };

  return (
    <Form.Group controlId="color" className="mb-3">
      <Form.Label>Color</Form.Label>
      <InputGroup className="mb-2">
        <Form.Control
          as="select"
          name="color"
          value={color}
          onChange={handleColorChange}
          aria-label="Select a color"
        >
          <option value="">Select a color</option>
          {/* Map over the available color variations */}
          {availableColors.map((colorOption, index) => (
            <option key={index} value={colorOption}>
              {colorOption}
            </option>
          ))}
        </Form.Control>
        <Button
          variant="outline-primary"
          onClick={onAddColor}
          aria-label="Add selected color"
        >
          Add
        </Button>
      </InputGroup>
      {/* Display selected colors as badges */}
      <div>
        {colors.map((colorItem, index) => (
          <span
            key={index}
            className="badge bg-primary d-inline-flex align-items-center me-2"
          >
            {colorItem}
            <AiOutlineClose
              size={15}
              className="ms-2 cursor-pointer"
              onClick={() => onRemoveColor(colorItem)}
              aria-label={`Remove ${colorItem}`}
            />
          </span>
        ))}
      </div>
    </Form.Group>
  );
};

export default ColorSelector;
