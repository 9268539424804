/* eslint-disable react/prop-types */
import { useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductVariants } from "../../../../../redux/actions/productVariantAction.js";

const SizeSelector = ({ sizeDetails, onAddSize, onRemoveSize, subcategoryId, selectedCategory, size, setSize }) => {

  const { productVariants } = useSelector((state) => state.productVariant);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductVariants());
  }, [dispatch]);

  // Filter the product variants to only include the desired subcategory
  const sizeVariants = productVariants?.find(
    (variant) => variant?.subcategory?._id === subcategoryId && variant.variantType === "Size"
  );

  // If the size variant is found, get availableSizes from the variant
  const availableSizes = sizeVariants?.availableSizes || [];


  // const handleCategoryChange = (e) => {
  //   setSelectedCategory(e.target.value);
  //   setSize(""); // Clear the selected size when category changes
  // };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const addSize = () => {
    if (size && selectedCategory) {
      onAddSize(selectedCategory, size); // Pass category and size
      setSize(""); // Clear the size input after adding
    }
  };

  return (
    <Form.Group controlId="size" className="mb-3">
      {/* <Form.Label>Size Category</Form.Label>
      <InputGroup className="mb-2">
        <Form.Control
          as="select"
          name="category"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="">Select Size Category</option>
          {sizeCategoriesArray.map((category) => (
            <option key={category} value={category}>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </option>
          ))}
        </Form.Control>
      </InputGroup> */}

      {subcategoryId && (
        <>
          <Form.Label>Select Size</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control
              as="select"
              name="size"
              value={size}
              onChange={handleSizeChange}
            >
              <option value="">Select Size</option>
              {/* Display sizes from availableSizes */}
              {availableSizes.map((sizeOption) => (
                <option key={sizeOption} value={sizeOption}>
                  {sizeOption}
                </option>
              ))}
            </Form.Control>

            <Button variant="outline-primary" onClick={addSize}>
              Add
            </Button>
          </InputGroup>
        </>
      )}

      {sizeDetails.map((detail, index) => (
        <div key={index} className="mt-3">
          <h5>Available Sizes</h5>
          {detail.sizes.map((sizeItem, i) => (
            <span
              key={i}
              className="badge bg-info d-inline-flex align-items-center me-2"
            >
              {sizeItem}
              <AiOutlineClose
                size={15}
                className="ms-2 cursor-pointer"
                onClick={() => onRemoveSize(detail.sizeCategory, sizeItem)}
              />
            </span>
          ))}
        </div>
      ))}
    </Form.Group>
  );
};

export default SizeSelector;
