/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { server } from "../../../server/server";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../../../redux/actions/productAction";
import { getAllSuppliers } from "../../../redux/actions/supplierAction";

const CreateInventory = ({ show, handleClose, fetchInventoryItems }) => {
    const dispatch = useDispatch();

    const { products } = useSelector((state) => state.product);
    const { suppliers } = useSelector((state) => state.supplier);

    const [formData, setFormData] = useState({
        product: "",
        color: "",
        size: "",
        supplier: "",
        quantity: 0,
    });

    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        dispatch(getAllProducts());
        dispatch(getAllSuppliers());
        setFormData({ product: "", color: "", size: "", supplier: "", quantity: 0 });
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => {
            const newState = { ...prevState, [name]: value };

            if (name === "product") {
                const product = products.find((prod) => prod._id === value);
                setSelectedProduct(product);
                newState.color = ""; // Reset color
                newState.size = ""; // Reset size
            }

            return newState;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = {
            product: formData.product,
            color: formData.color,
            size: formData.size,
            supplier: formData.supplier,
            quantity: formData.quantity,
        };

        try {
            await axios.post(`${server}/inventory/create-item`, newFormData, { withCredentials: true });
            toast.success("Inventory item added successfully");
            fetchInventoryItems();
            setFormData({ product: "", color: "", size: "", supplier: "", quantity: 0 });
            handleClose();

        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to add inventory item");
        }
    };


    return (
        <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Add Inventory Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formProduct">
                        <Form.Label>Product</Form.Label>
                        <Form.Control
                            as="select"
                            name="product"
                            value={formData.product}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Product</option>
                            {products.map((product) => (
                                <option key={product._id} value={product._id}>
                                    {product.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {selectedProduct && (
                        <>
                            <Form.Group controlId="formColor">
                                <Form.Label>Color</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="color"
                                    value={formData.color}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Color</option>
                                    {selectedProduct.colors.map((color) => (
                                        <option key={color} value={color}>
                                            {color}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formSize">
                                <Form.Label>Size</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="size"
                                    value={formData.size}
                                    onChange={handleChange}
                                // required
                                >
                                    <option value="">Select Size</option>
                                    {selectedProduct.sizeDetails.flatMap((detail) =>
                                        detail.sizes.map((size) => (
                                            <option key={size} value={size}>
                                                {size}
                                            </option>
                                        ))
                                    )}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formSupplier">
                                <Form.Label>Supplier</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="supplier"
                                    value={formData.supplier}
                                    onChange={handleChange}
                                // required
                                >
                                    <option value="">Select Supplier</option>
                                    {suppliers.map((supplier) => (
                                        <option key={supplier._id} value={supplier._id}>
                                            {supplier.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </>
                    )}

                    <Form.Group controlId="formQuantity">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={(e) => handleChange(e)}
                            required
                            min="0"
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit" className="mt-2 float-end">
                        Add Inventory
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateInventory;
