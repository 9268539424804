import { useEffect, useState } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AdminDashboard, AdminFooter, AdminNavbar, AdminSidebar,
  BillingPage, CategoriesPage, CustomersPage, ExpensesPage,
  InventoryPage, InventoryReports, LoginPage, OrdersPage,
  PosDashboard, ProductsPage, ProductUnitPage, ProductVariantsPage,
  RolesPage, SalesPage, SalesReports, SettingsPage, StoreInventoryPage,
  StoresPage, StoreStockPage, SubCategoriesPage, SuppliersPage, UserProfilePage, UsersPage,
  VariantsPage,
} from "./admin/routes/AdminRoutes";

import AdminProtectedRoute from "./admin/routes/AdminProtectedRoute.jsx";
import PageNotFound from "./components/page-not-found/PageNotFound.jsx";


import { loadUser } from "./redux/actions/userAction.js";
import { store } from "./redux/Store.js";


const App = () => {

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  const AdminLayout = () => {
    const [showSidebar, setShowSidebar] = useState(false);

    const handleCloseSidebar = () => setShowSidebar(false);
    const handleShowSidebar = () => setShowSidebar(true);

    return (
      <>
        <AdminNavbar handleShowSidebar={handleShowSidebar} />
        <AdminSidebar show={showSidebar} handleClose={handleCloseSidebar} handleShow={handleShowSidebar} />
        <div className="main">
          <Outlet />
        </div>
        <AdminFooter />
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: "login",
      element: <LoginPage />
    },
    {
      path: "/",
      element: (
        <AdminProtectedRoute>
          <AdminLayout />
        </AdminProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <PosDashboard />
        },
        {
          path: "pos-dashboard",
          element: <PosDashboard />
        },
        {
          path: "store-products",
          element: <StoreStockPage />
        },
        {
          path: "store-inventory",
          element: <StoreInventoryPage />
        },
        {
          path: "roles",
          element: <RolesPage />
        },
        {
          path: "stores",
          element: <StoresPage />
        },
        {
          path: "users",
          element: <UsersPage />
        },
        {
          path: "dashboard",
          element: <AdminDashboard />
        },
        {
          path: "dashboard",
          element: <AdminDashboard />
        },
        {
          path: "suppliers",
          element: <SuppliersPage />
        },
        {
          path: "orders",
          element: <OrdersPage />
        },
        {
          path: "product-categories",
          element: <CategoriesPage />
        },
        {
          path: "sub-categories",
          element: <SubCategoriesPage />
        },
        {
          path: "product-units",
          element: <ProductUnitPage />
        },
        {
          path: "variants",
          element: <VariantsPage />
        },
        {
          path: "product-variants",
          element: <ProductVariantsPage />
        },
        {
          path: "products",
          element: <ProductsPage />
        },
        {
          path: "customers",
          element: <CustomersPage />
        },
        {
          path: "inventory",
          element: <InventoryPage />
        },
        {
          path: "sales",
          element: <SalesPage />
        },
        {
          path: "billing",
          element: <BillingPage />
        },
        {
          path: "expenses",
          element: <ExpensesPage />
        },
        {
          path: "user-profile",
          element: <UserProfilePage />
        },
        {
          path: "inventory-reports",
          element: <InventoryReports />
        },
        {
          path: "sales-reports",
          element: <SalesReports />
        },
        {
          path: "settings",
          element: <SettingsPage />
        },
      ]
    },
    {
      path: "*",
      element: <PageNotFound />
    }
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
